<template>
  <van-cell title="微信昵称" style="margin:7px 0 0 0;" />
  <div style="background:#fff;padding:15px;">
    <van-row>
      <van-col span="6">
        <van-image
        fit="cover"
        round
        width="4rem"
        height="4rem"
        :src="avatar"
      />
      </van-col>
      <van-col span="18">
        <h5 style="margin:13px 0 5px 0;">{{ name }}</h5>
      </van-col>
    </van-row>
  </div>
  <!-- <van-cell center title="绑定微信" style="margin:7px 0 0 0;">
    <template #right-icon>
      <van-switch :model-value="checked" @update:model-value="onUpdateValue" size="24" :loading="loading" />
    </template>
  </van-cell> -->
  <van-cell center title="关联手机号" style="margin:7px 0 0 0;"/>
  <van-cell-group title="">
    <van-field
      v-model="phone"
      name="phone"
      label="旧手机号"
      disabled
      placeholder="请输入"
    />
    <van-field
      v-model="captcha"
      name="captcha"
      center
      clearable
      label="验证码"
      placeholder="请输入"
    >
      <template #button>
        <van-button size="small" type="primary" @click="onSendCaptcha">发送验证码</van-button>
      </template>
    </van-field>
    <van-field
      v-model="newPhone"
      name="phone"
      label="新手机号"
      placeholder="请输入"
    />
  </van-cell-group>
  <div style="margin: 16px;">
    <van-button round block type="primary" @click="changeBind()">确认换绑</van-button>
  </div>
  <div class="account-settings-tips">
    <p>注意：</p>
    <span>换绑新的手机号后旧手机号将收不到分账收益，请谨慎操作。</span>
    <span>换绑后下次登录需要使用新的手机号登录。</span>
  </div>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import req from '@/util/request-new'
import { Toast, Dialog } from 'vant'

export default {
  setup () {
    const Cookies = inject('Cookies')
    const useRoute = inject('useRoute')
    const useRouter = inject('useRouter')
    const state = reactive({
      name: Cookies.get('wechatNickname'),
      avatar: Cookies.get('wechatHeadimgurl'),
      wechatOpenid: Cookies.get('wechatOpenid'),
      checked: false,
      loading: false,
      userId: Cookies.get('userId'),
      phone: useRoute.query.oldPhone ?? '',
      newPhone: '',
      captcha: ''
    })
    const vantDialog = (obj, callback) => {
      Dialog.confirm(obj).then(() => {
        callback()
      })
    }
    const onUpdateValue = (newValue) => {
      state.loading = true
      req.post('/user.bindWechat', {
        userId: state.userId,
        bind: newValue,
        wechatOpenid: Cookies.get('wechatOpenid')
      }).then(res => {
        state.loading = false
        if (res.code === 0) {
          state.checked = newValue
        }
      })
    }
    const onSendCaptcha = () => {
      req.post('/util.sendCaptcha', {
        phone: state.phone
      }).then(res => {
        if (res.code === 0) {
          Toast.success('短信已发送')
        } else {
          Toast(res.msg)
        }
      })
    }
    const changeBind = () => {
      vantDialog({
        title: '您确认将当前微信绑定给新的手机号吗',
        confirmButtonText: '确定'
      }, () => {
        req.post('/user.changebinding', {
          phoneold: state.phone,
          captcha: state.captcha,
          phonenew: state.newPhone
        }).then(res => {
          if (res.code === 0) {
            Toast.success('换绑成功')
            useRouter.push({
              name: 'Login',
              query: {
                redirect: '/user',
                role: 'BUYER'
              }
            })
          } else {
            Toast(res.msg)
          }
        })
      })
    }
    const init = () => {
      req.post('/user.get', {
        userId: state.userId
      }).then(res => {
        if (res.code === 0) {
          state.checked = res.data.wechatOpenid !== ''
        }
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onUpdateValue,
      onSendCaptcha,
      changeBind
    }
  }
}
</script>

<style>
  .account-settings-tips{
    margin-top: 30px;
  }
  .account-settings-tips p{
    font-size: 15px;
    color: red;
    padding-left: 12px;
  }
  .account-settings-tips span{
    display: block;
    font-size: 13px;
    color: red;
    padding-left: 12px;
    margin-top: 3px;
  }
</style>
